import { BrandHeaderBase } from "../../../components/BrandHeaderBase";
import { Distributor, Theme } from "../../../model/theme.typer";
import { SB1Logo } from "../logo";
import "./sb1.scss";

const BrandHeader = () => <BrandHeaderBase logo={<SB1Logo />} />;

export const theme: Theme = {
    distributor: Distributor.SB1,
    BrandHeader
};
